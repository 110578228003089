import React, { useEffect, useState } from "react";
import { navigate } from "gatsby";

import { Downgraded, useState as useHookState } from "@hookstate/core";
import { useLocation } from "@reach/router";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faUserCircle } from "@fortawesome/free-solid-svg-icons";

import DropDown from "../DropDown";

import ParkGenieLogo from "../../../images/logo_partner@2x.png";
import CloseIcon from "../../../images/close1@2x.png";
import ProfileIcon from "../../../images/home_tab_user_icon@2x.png";

import AppState from "../../../States/AppState";
import useWindowDimensions from "../../../Hooks/useWindowDimensions";

import * as HeaderStyle from "./Header.module.scss";

const Index = () => {
  const user = useHookState(AppState).attach(Downgraded).user.get();
  const location = useLocation().pathname;
  const [isSideNavBar, setIsSideNavbar] = useState(false);
  const [isDropDown, setIsDropDown] = useState(false);

  const { width } = useWindowDimensions();

  const isUserAuthenticated = useHookState(AppState).isUserAuthenticated.get();

  const menuOptions = [
    { id: 1, label: "Home", url: "/", img: "", cls: undefined },
    { id: 2, label: "AboutUs", url: "/aboutus", img: "", cls: undefined },
    { id: 3, label: "FAQ", url: "", img: "/faq", cls: "" },
    { id: 4, label: "How It Works", url: "", img: "", cls: undefined },
    { id: 5, label: "Login", url: "/login", img: "", cls: "" },
    { id: 6, label: "|", url: "", img: "", cls: "hideOnMobileMd" },
    { id: 7, label: "SignUp", url: "/signup", img: "", cls: undefined },
  ];

  const loggedInMenuOptions = [
    {
      id: 1,
      label: "Home",
      url: "/",
      img: "",
      type: undefined,
    },
    {
      id: 1,
      label: "About Us",
      url: "/aboutus",
      img: "",
      type: undefined,
    },
    {
      id: 1,
      label: "How It Works",
      url: "/howitworks",
      img: "",
      type: undefined,
    },
  ];

  const mobileLoggedInMenu = [
    {
      id: 1,
      label: "Home",
      url: "/",
      img: "",
      type: undefined,
    },
    {
      id: 2,
      label: "Profile",
      url: "/profile",
      img: "",
      type: undefined,
    },
    {
      id: 3,
      label: "Bookings",
      url: "/bookings",
      img: "",
      type: undefined,
    },
    {
      id: 4,
      label: "Statistics",
      url: "/statistics",
      img: "",
      type: undefined,
    },
    {
      id: 5,
      label: "Invitation",
      url: "/invitation",
      img: "",
      type: undefined,
    },
    {
      id: 6,
      label: "Corporate Invitation",
      url: "/corporateinvitation",
      img: "",
      type: undefined,
    },
    {
      id: 7,
      label: "Transaction",
      url: "/transaction",
      img: "",
      type: undefined,
    },
    {
      id: 8,
      label: "How It Works",
      url: "/howitworks",
      img: "",
      type: undefined,
    },
    {
      id: 9,
      label: "About Us",
      url: "/aboutus",
      img: "",
      type: undefined,
    },
  ];

  return (
    <>
      <div className={HeaderStyle.container}>
        <button
          className={HeaderStyle.toggleBtn}
          onClick={() => setIsSideNavbar(true)}
        >
          <FontAwesomeIcon icon={faBars} color="white" size="2x" />
        </button>
        <div>
          <img className={HeaderStyle.logo} src={ParkGenieLogo} alt="logo" />
        </div>
        <ul
          className={`${HeaderStyle.list} ${
            isSideNavBar ? HeaderStyle.list__active : ""
          }`}
        >
          <div className={`${HeaderStyle.list__header}`}>
            <div className={HeaderStyle.list__header__img}>
              {user?.userMedias?.collection.length ? (
                <img
                  src={user.userMedias.collection[0]?.mediaUrl?.url}
                  width={64}
                  height={64}
                />
              ) : (
                <FontAwesomeIcon
                  icon={faUserCircle}
                  className="primaryColor"
                  size="5x"
                />
              )}

              <div className="primaryColor">
                {AppState.isUserAuthenticated.get()
                  ? user?.name || "No Name"
                  : "Welcome Guest"}
              </div>
            </div>
          </div>
          <button
            className={HeaderStyle.closeBtn}
            onClick={() => setIsSideNavbar(false)}
          >
            <img src={CloseIcon} alt="" width={48} height={48} />
          </button>
          {!isUserAuthenticated ? (
            <>
              {menuOptions.map(({ img, label, url, cls, id }) => (
                <li
                  key={`${url}${id}guest`}
                  className={`${HeaderStyle.list__item} ${cls ? cls : ""}`}
                >
                  <button
                    onClick={() => navigate(url)}
                    className={HeaderStyle.menu}
                  >
                    {label}
                  </button>
                </li>
              ))}
            </>
          ) : (
            <>
              {width > 1000 ? (
                <>
                  {loggedInMenuOptions.map(({ img, label, url, id, type }) => (
                    <li
                      key={`${id}${url}user`}
                      className={`${HeaderStyle.list__item}`}
                    >
                      <button
                        onClick={() => {
                          if (type === "Profile") {
                            setIsDropDown(!isDropDown);
                          } else {
                            navigate(url);
                          }
                        }}
                        className={HeaderStyle.menu}
                      >
                        {label}
                      </button>
                    </li>
                  ))}
                  {width > 1000 && (
                    <li className={`${HeaderStyle.list__item}`}>
                      <button
                        onClick={() => {
                          setIsDropDown(!isDropDown);
                        }}
                        className={`${HeaderStyle.menu}`}
                      >
                        <img
                          src={
                            user?.userMedias?.collection[0]?.mediaUrl?.url ||
                            ProfileIcon
                          }
                          alt=""
                          width={32}
                          height={32}
                          {...{ style: { borderRadius: "100px" } }}
                        />
                      </button>
                      <DropDown isOpen={isDropDown} />
                    </li>
                  )}
                </>
              ) : (
                <>
                  {mobileLoggedInMenu.map(({ img, label, url, id, type }) => (
                    <li
                      key={`${id}${url}user`}
                      className={`${HeaderStyle.list__item}`}
                    >
                      <button
                        onClick={() => {
                          if (type === "Profile") {
                            setIsDropDown(!isDropDown);
                          } else {
                            navigate(url);
                          }
                        }}
                        className={`${HeaderStyle.menu} ${
                          location === url ? HeaderStyle.activeItem : ""
                        }`}
                      >
                        {label}
                      </button>
                    </li>
                  ))}
                </>
              )}
            </>
          )}
        </ul>
      </div>
      {(isDropDown || isSideNavBar) && (
        <div
          onClick={() => {
            setIsDropDown(false);
            setIsSideNavbar(false);
          }}
          className="overlay"
        >
          &nbsp;
        </div>
      )}
    </>
  );
};

export default Index;
