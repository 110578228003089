import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookF,
  faTwitter,
  faPinterest,
  faMailchimp,
  faWhatsapp,
} from "@fortawesome/free-brands-svg-icons";

import Logo from "../../../images/footer_logo@2x.png";
import LocationIcon from "../../../images/contactus_location_icon_white@2x.png";
import EmailIcon from "../../../images/contactus_email_icon_white@2x.png";
import PhoneIcon from "../../../images/contactus_phone_icon_white@2x.png";
import FacebookIcon from "../../../images/facebook_icon@2x.png";
import TwitterIcon from "../../../images/twitter_icon@2x.png";
import YoutubeIcon from "../../../images/youtube_icon@2x.png";

import * as FooterStyle from "./Footer.module.scss";
import { navigate } from "gatsby";
import moment from "moment";

const Index = () => {
  const menu = [
    { label: "Home", link: "/" },
    { label: "About Us", link: "/aboutus" },
    { label: "How it Works", link: "/howitwork" },
    { label: "Privacy Policy", link: "/privacypolicy" },
  ];
  return (
    <div className={FooterStyle.container}>
      <div className={FooterStyle.share}>
        <div className={FooterStyle.share__count}>
          <span>503</span>
          <span>Shares</span>
        </div>
        <div className={FooterStyle.share__buttons}>
          <button className={`${FooterStyle.btn} ${FooterStyle.btn__facebook}`}>
            <FontAwesomeIcon icon={faFacebookF} className={FooterStyle.white} />
            <span>Share</span>
          </button>
          <button className={`${FooterStyle.btn} ${FooterStyle.btn__twitter}`}>
            <FontAwesomeIcon icon={faTwitter} className={FooterStyle.white} />
            <span>Tweet</span>
          </button>
          <button
            className={`${FooterStyle.btn} ${FooterStyle.btn__pinterest}`}
          >
            <FontAwesomeIcon icon={faPinterest} className={FooterStyle.white} />
            <span>Pin</span>
          </button>
          <button className={`${FooterStyle.btn} ${FooterStyle.btn__mail}`}>
            <FontAwesomeIcon icon={faMailchimp} className={FooterStyle.white} />
            <span>Email</span>
          </button>
          <button className={`${FooterStyle.btn} ${FooterStyle.btn__whatsapp}`}>
            <FontAwesomeIcon icon={faWhatsapp} className={FooterStyle.white} />
            <span>Share</span>
          </button>
        </div>
      </div>
      <div className={FooterStyle.content}>
        <div className={FooterStyle.header}>
          <h2>Rent Out Your Parking Space?</h2>
          <button>Click Here</button>
        </div>
        <div className={FooterStyle.footer}>
          <div className={FooterStyle.footer__aboutus}>
            <img src={Logo} alt="logo" width={150} />
            <h6 className={FooterStyle.h6}>About The ParkGenie App</h6>
            <p>
              A new and easy way of solving parking problems, saving time and
              reducing pollution. Simple solution with potentially big impact on
              how and where we park out cars
            </p>
          </div>
          <div className={FooterStyle.footer__menu}>
            <h6 className={FooterStyle.h6}>Menu</h6>
            <div className={FooterStyle.footer__menu__content}>
              {menu.map(({ label, link }) => (
                <button
                  className={FooterStyle.button}
                  key={`${label}/${link}`}
                  onClick={() => navigate(link)}
                >
                  {label}
                </button>
              ))}
            </div>
          </div>
          <div className={FooterStyle.footer__tnc}>
            <h6 className={FooterStyle.h6}>Terms &amp; Conditions</h6>
            <div className={FooterStyle.footer__tnc__content}>
              <button className={FooterStyle.button}>
                Terms &amp; conditions
              </button>
              <button className={FooterStyle.button}>Refund policy</button>
              <button className={FooterStyle.button}>Contact Us</button>
            </div>
          </div>
          <div className={FooterStyle.footer__contactus}>
            <h6 className={FooterStyle.h6}>Contact Us</h6>
            <div className={FooterStyle.footer__contactus__list}>
              <span>United Web Enhancers Pvt.Ltd</span>
              <p className={FooterStyle.footer__contactus__list__item}>
                <img src={LocationIcon} alt="location" width={16} height={16} />
                <span>
                  536B,B3,Spaze-i-tech Park Sohna Road, Gurugram,Haryana
                </span>
              </p>
              <a
                className={FooterStyle.footer__contactus__list__item}
                href="mailto:info@parkgenie.in"
              >
                <img src={EmailIcon} alt="mail" width={16} height={16} />
                <span>info@parkgenie.in</span>
              </a>
              <p className={FooterStyle.footer__contactus__list__item}>
                <img src={PhoneIcon} alt="call" width={16} height={16} />
                <span>+91-124-4227149</span>
              </p>
            </div>
          </div>
        </div>
        <div className={FooterStyle.footer__copyright}>
          <span>
            &copy; Copyright 2017-{moment().format("YYYY")}. All Rights Reserved
          </span>
          <div>
            <span>We are in</span>
            <img src={FacebookIcon} alt="facebook" width={20} height={20} />
            <img src={TwitterIcon} alt="twitter" width={20} height={20} />
            <img src={YoutubeIcon} alt="youtube" width={20} height={20} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Index;
