import React, { ReactElement, useEffect } from "react";

import { navigate } from "gatsby";
import { useState as useHookState } from "@hookstate/core";

import Loader from "../Loader";

import Header from "./Header";
import Footer from "./Footer";

import AppState from "../../States/AppState";

import "../../Styles/App.scss";

import * as LayoutStyle from "./layout.module.scss";

interface Props {
  showHeader?: boolean;
  children: ReactElement | ReactElement[];
  isPrivate?: boolean;
  nonLogingPage?: boolean;
}

const Index = ({ showHeader, children, isPrivate, nonLogingPage }: Props) => {
  const isLoggedIn = useHookState(AppState).isUserAuthenticated.get();
  const isStarting = useHookState(AppState).isStarting.get();

  useEffect(() => {
    if (!isLoggedIn && isPrivate && !isStarting) {
      navigate("/login");
    }
  }, [isLoggedIn, isStarting]);

  useEffect(() => {
    if (nonLogingPage && !isStarting && isLoggedIn) {
      navigate("/");
    }
  }, [nonLogingPage, isStarting, isLoggedIn]);

  useEffect(() => {
    console.log(isLoggedIn, isStarting, isPrivate);
  }, [isLoggedIn, isStarting]);

  return (
    (isLoggedIn || !isPrivate) && (
      <div className={LayoutStyle.container}>
        {showHeader && <Header />}
        <div className={LayoutStyle.content}>{children}</div>
        <Footer />
      </div>
    )
  );
};

export default Index;
