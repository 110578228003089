import { navigate } from "gatsby";
import React from "react";

import BookingIcon from "../../../images/booking_calendar@2x.png";
import CorporateInvitationIcon from "../../../images/corporate_icon@2x.png";
import ProfileIcon from "../../../images/home_profile_icon@2x.png";
import BulkInvitaionIcon from "../../../images/invitation_icon@2x.png";
import LogoutIcon from "../../../images/logout_icon@2x.png";
import ParkingAreasIcon from "../../../images/my_parking_area_icon@2x.png";
import StatisticsIcon from "../../../images/statistic_icon@2x.png";
import TransanctionIcon from "../../../images/transaction_icon@2x.png";
import { Logout } from "../../../utils/Auth";

import * as DropDownStyle from "./DropDown.module.scss";

interface Props {
  isOpen: boolean;
}

const Index = ({ isOpen }: Props) => {
  const menuList = [
    { id: 1, label: "Profile", icon: ProfileIcon, url: "/profile" },
    { id: 2, label: "My Parking Area", icon: ParkingAreasIcon, url: "/" },
    { id: 3, label: "Bookings", icon: BookingIcon, url: "/bookings" },
    { id: 4, label: "Statistics", icon: StatisticsIcon, url: "/statistics" },
    {
      id: 5,
      label: "Invitation",
      icon: BulkInvitaionIcon,
      url: "/invitation",
    },
    {
      id: 6,
      label: "Corporate Invitation",
      icon: CorporateInvitationIcon,
      url: "/corporateinvitation",
    },
    {
      id: 7,
      label: "Transaction",
      icon: TransanctionIcon,
      url: "/transaction",
    },
  ];
  return (
    <div
      className={`${DropDownStyle.menu__dropDown} ${
        isOpen ? DropDownStyle.menu__dropDown_active : ""
      }`}
    >
      <ul className={DropDownStyle.list}>
        {menuList.map(({ icon, id, label, url }) => (
          <button onClick={() => navigate(url)} key={`${id}${url}menu`}>
            <li className={DropDownStyle.list__item}>
              <img src={icon} alt="label" width={28} height={28} />
              <div>{label}</div>
            </li>
          </button>
        ))}
        <button
          onClick={() => {
            Logout().then(() => {
              navigate("/login");
            });
          }}
        >
          <li className={DropDownStyle.list__item}>
            <img src={LogoutIcon} alt="label" width={28} height={28} />
            <div>Log Out</div>
          </li>
        </button>
      </ul>
    </div>
  );
};

export default Index;
