// extracted by mini-css-extract-plugin
export var container = "Footer-module--container---QxfN";
export var share = "Footer-module--share--VyJl1";
export var share__count = "Footer-module--share__count--1Spco";
export var share__buttons = "Footer-module--share__buttons--7qDY6";
export var btn = "Footer-module--btn--j923w";
export var btn__facebook = "Footer-module--btn__facebook--Sobfd";
export var btn__twitter = "Footer-module--btn__twitter--Qiqoa";
export var btn__pinterest = "Footer-module--btn__pinterest--KTOiW";
export var btn__mail = "Footer-module--btn__mail--duzy8";
export var btn__whatsapp = "Footer-module--btn__whatsapp--ItOiZ";
export var content = "Footer-module--content--mnLXa";
export var header = "Footer-module--header--j0-NF";
export var white = "Footer-module--white--OGhtX";
export var footer = "Footer-module--footer--fwFDy";
export var footer__aboutus = "Footer-module--footer__aboutus--vVskM";
export var footer__menu = "Footer-module--footer__menu--y7YVt";
export var footer__menu__content = "Footer-module--footer__menu__content--CCcdR";
export var footer__tnc = "Footer-module--footer__tnc--aQ6R2";
export var footer__tnc__content = "Footer-module--footer__tnc__content--9kq8B";
export var footer__contactus = "Footer-module--footer__contactus--9-lJ8";
export var footer__contactus__list = "Footer-module--footer__contactus__list--xUEII";
export var footer__contactus__list__item = "Footer-module--footer__contactus__list__item--stWwP";
export var footer__copyright = "Footer-module--footer__copyright--toT26";
export var h6 = "Footer-module--h6--3cA5e";
export var button = "Footer-module--button--ixFCc";